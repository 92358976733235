import React from 'react'
import { Link, graphql } from 'gatsby'
import PageHeader from 'components/page-header'

import Layout from 'components/layout'

const OpeningsPage = ({ data }) => (
  <Layout>
    <PageHeader 
        title="Open Positions"
        sizes={data.headerImage.childImageSharp.fluid}
      />
    <div className="l-primary">
      <nav>
        <h3>Careers</h3>
        <ul>
          <li>
            <Link to={'/careers/open-positions'}>Open Positions <span className="label">{data.allJobs.totalCount}</span></Link>
            {data.allJobs.edges.length > 0 && (
              <ul>
                {data.allJobs.edges.map(job => (
                  <li><Link to={job.node.fields.slug}>{job.node.frontmatter.position}</Link></li>
                ))}
              </ul>
            )}
          </li>
          <li><Link to={'/careers/company-culture'}>Company Culture</Link></li>
          <li><Link to={'/careers/core-values'}>Core Values</Link></li>
        </ul> 
      </nav>
      <section>
        {data.allJobs.edges.length 
          ? <p className="no-drop-cap">Below is the list of all positions for which we are currently hiring.</p>
          : <p className="no-drop-cap">There are currently no open positions.</p>
        }
        {data.allJobs.edges.map(job => (
          <div className="job-opening">
            <h2><Link to={job.node.fields.slug}>{job.node.frontmatter.position}</Link></h2>
            <dl>
              <dt>Status</dt>
              <dd>{job.node.frontmatter.status}</dd>
              <dt>Salary</dt>
              <dd>{job.node.frontmatter.salary}</dd>
              <dt>Location</dt>
              <dd>{job.node.frontmatter.location}</dd>
              <dt>Reports To</dt>
              <dd>{job.node.frontmatter.reports_to}</dd>
            </dl>
            <Link to={job.node.fields.slug} className="buttonLink--small">Learn more and apply</Link>
          </div>
        ))}
        
      </section>
      <aside>
          <h3>Join Our Team</h3>
          <p>Howell Farms offers the prospect of rewarding careers for hardworking individuals dedicated to learning and personal advancement.</p>
          <Link to={'/careers/company-culture'} className="buttonLink--small">Learn more</Link>
        </aside>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    allJobs: allMarkdownRemark(
      filter: { 
        fields: { 
          type: { eq: "jobs"}
        }
      }, 
      sort: {fields: [frontmatter___position], order: ASC}
    ) {
      totalCount
      edges {
        node {
          fields {
            type
            slug
          }
          html
          excerpt
          frontmatter {
            position
            status
            salary
            reports_to
            location
          }
        }
      }
    }
    headerImage: file(relativePath: { eq: "farm-team-header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default OpeningsPage
